.--crsr {
  cursor: default;

  &-i {
    cursor: default !important;
  }

  &-pntr {
    cursor: pointer;
  }
}
